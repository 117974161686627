import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Business consulting`}</h2>
    <p>{`Although highly praised for its creativity, `}<em parentName="p">{`A tu aire`}</em>{` was suffering from a lack of quality in their delivery of services. `}</p>
    <p>{`The first task was to identify their core functionalities by mapping out their processes and responsible staff for each. `}</p>
    <p>{`Their time was mostly used on two core functions: reservations and planning. Since reservations could be handled by their own customers this process was quickly turned into an automated process. By integrating various tools including payments and invoices into the same platform, that also connected to the web, their staff was free to work more on planning the next activities.`}</p>
    <h2>{`Branding`}</h2>
    <p>{`A rebrand that included launching an updated website that focused on UI/UX and easy updates using a Wordpress stack. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      